<template>
  <div class="app-container">
    <eHeader :query="query"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="userName" label="用户名"/>
      <el-table-column prop="tableName" label="表名"/>
      <el-table-column prop="columnName" label="字段名"/>
      <el-table-column prop="columnDataType" label="字段类型"/>
      <el-table-column prop="operation" label="操作符"/>
      <el-table-column prop="columnValue" label="字段值"/>
      <el-table-column prop="comment" label="备注"/>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span>{{ scope.row.status ? '启用':'禁用' }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import eHeader from '@/components/system/dataper/dpgen/header'
export default {
  name:'dpgen',
  components: { eHeader },
  mixins: [initData],
  data() {
    return {
      delLoading: false, sup_this: this
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = 'user/crm/srPermissionGen'
      const sort = 'id,desc'
      this.params = { page: this.page, size: this.size, sort: sort }
      const query = this.query
      const userName = query.userName
      const tableName = query.tableName
      if (userName !== '' && userName !== null) { this.params['userName'] = userName }
      if (tableName !== '' && tableName !== null) { this.params['tableName'] = tableName }
      return true
    }
  }
}
</script>

<style scoped>

</style>
